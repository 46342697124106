import React from 'react'

import SEO from '../components/SEO'
import PageTitle from '../components/PageTitle'
import Logo from '../images/success-svgrepo-com.svg'
import { Link } from 'gatsby'

const ThankYouPage = () => (
  <>
    <SEO title="Matys carpentry - Professional and comprehensives services in the construction business." />

    <div className="max-w-sm text-center m-auto py-32">
      <img src={Logo} className="w-24" alt="Thank you" />
      <PageTitle description="Your message was sent!">Thank you!</PageTitle>
      <Link
        to={`/`}
        className="inline-block appearance-none border border-black text-white bg-black hover:bg-white px-4 py-3 leading-tight rounded-none focus:outline-none md:my-2 no-underline self-end "
      >Back to home</Link>
    </div>
  </>
)

export default ThankYouPage
